@import url('https://fonts.googleapis.com/css?family=Lato');

.navBarLogo {
    width: 150px;
}

.loginBoxLogo {
    width: 316px;
}

#navBar {
    background: #FFFFFF;
    height: 64px;
}

#settingOnNavBar {
    flex: 1;
    display: flex;
    justify-content: flex-end;

}

#navRightBarMenu {
    display: flex;
    justify-content: flex-end;
    width: 49px;
    height: 24px;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 24px;
    text-align: right;
    letter-spacing: 0.4px;
    color: #212121;
}

#helpSupport {
    flex: .1;
    display: flex;
    justify-content: flex-end;
    width: 49px;
    height: 24px;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 24px;
    text-align: right;
    letter-spacing: 0.4px;
    color: #212121;
    cursor: pointer;
}

#navBarRightNavigation {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    color: #616161;
}

#loginBox {
    box-sizing: border-box;
    position: absolute;
    width: 1244px;
    height: 548px;
    left: 98px;
    top: 106px;
    background: #FFFFFF;
    border: 1px solid #DEDEDE;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
}

#arrowImg {
    position: absolute;
    width: 60%;
    height: 90%;
    border-radius: 0px 3px 3px 0px;
}

#box1 {
    width: 50%;
    height: 100%;
    text-align: right;
}

#box2 {
    width: 50%;

    height: 100%;
}

#logButton {
    background: #00A3BA;
    color: #FFFFFF;
}

#addNewBudgetButton {
    background: #00A3BA;
    color: #FFFFFF;
    height: 48px;
    min-width: 240px;
    font-size: 12px;

}

#smallActionButton {
    width: 130px;
    height: 48px;
    background: #00A3BA;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    color: #FFFFFF;
}

#dynamicWidthButton {
    width: fit-content;
    min-width: 130px;
    height: 48px;
    background: #00A3BA;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    color: #FFFFFF;
    font-size: 13px;
}

#helpText {
    position: relative;
}

#abc {
    display: flex;
    justify-content: flex-start;
}
