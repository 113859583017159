.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Spreadsheet-Major-head-group {
  color: #212121;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
}

.Spreadsheet-Major-head {
  color: #212121;
  font-weight: 700;
  font-size: 13px;
  line-height: 24px;
  padding-left: 1em;
}

.Spreadsheet__table th {
  color: #212121;
  background-color: #F9F9F9;
  font-weight: 700;
  font-size: 13px;
  fontFamily: "Lato";
}

.Spreadsheet-particulars {
  color: #212121;
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
}

.Spreadsheet-particulars-index {
  color: #212121;
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  padding-left: 2em;
}

.Spreadsheet-number {
  color: #212121;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: right;
}

.Spreadsheet-total-particulars {
  color: #212121;
  font-weight: 700;
  font-size: 13px;
  line-height: 24px;
  background-color: #f2f2f2;
}

.Spreadsheet-total-number {
  color: #212121;
  font-weight: 700;
  font-size: 13px;
  line-height: 24px;
  background-color: #f2f2f2;
  text-align: right;
}

.Spreadsheet-addNewLineBox {
    color: #212121;
    box-sizing: border-box;
    /*position: center;*/
    /*width: 100%;*/
    font-size: 13px;
    position: absolute;
    background: #FFFFFF;
    border: 1px solid rgba(33, 33, 33, 0.33);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    cursor: pointer;
}
.Spreadsheet-deleteButton {
    content: url('./assets/dash-circle-fill.svg');
    cursor: pointer;
}

.Spreadsheet-deleteButton.disable {
    opacity: 50%;
    cursor: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

